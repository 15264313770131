import "../../../js/import/slick";
$(document).ready(function(){
    $('.reviews__items').slick({
        centerMode: true,
        centerPadding: '410px',
        customPaging: '40px',
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        prevArrow: '<button type="button" class="slick-prev"><img src="img/slider_right.png"></button>',
        nextArrow: '<button type="button" class="slick-next"><img src="img/slider_left.png"></button>',
        responsive: [
          {
            breakpoint: 1520,
            settings: {
              centerPadding: '160px',
              prevArrow: '<button type="button" class="slick-prev"><img src="img/slider_right.png"></button>',
              nextArrow: '<button type="button" class="slick-next"><img src="img/slider_left.png"></button>',
            }
          },
          {
            breakpoint: 1200,
            settings: {
              centerPadding: '110px',
              prevArrow: '<button type="button" class="slick-prev"><img src="img/slider_right.png"></button>',
              nextArrow: '<button type="button" class="slick-next"><img src="img/slider_left.png"></button>',
            }
          },
            {
              breakpoint: 992,
              settings: {
                centerPadding: '30px',
                prevArrow: '<button type="button" class="slick-prev"><img src="img/slider_right.png"></button>',
                nextArrow: '<button type="button" class="slick-next"><img src="img/slider_left.png"></button>',
              }
            },
            {
              breakpoint: 768,
              settings: {
                centerPadding: '-1000px',
                prevArrow: '<button type="button" class="slick-prev"><img src="img/slider_right.png"></button>',
                nextArrow: '<button type="button" class="slick-next"><img src="img/slider_left.png"></button>',
              }
            },
            {
              breakpoint: 576,
              settings: {
                arrows: false,
                centerPadding: '30px',
                dots: false,
                prevArrow: '<button type="button" class="slick-prev"><img src="img/arrow_left.png"></button>',
                nextArrow: '<button type="button" class="slick-next"><img src="img/arrow_right.png"></button>'
              }
            }
          ]
    });
});