import "%modules%/forms/forms";
import "%modules%/header/header";
import "%modules%/advantages/advantages";
import "%modules%/delivery/delivery";
import "%modules%/agent/agent";
import "%modules%/prices/prices";
import "%modules%/consultation/consultation";
import "%modules%/managers/managers";
import "%modules%/scheme/scheme";
import "%modules%/reviews/reviews";
import "%modules%/questions/questions";
import "%modules%/map/map";
import "%modules%/footer/footer";
import "%modules%/pageup/pageup";