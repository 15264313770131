$(document).ready(function(){
  
    function toggleSlide (item){
      $(item).each(function(i){
        $(this).on('click', function(e) {
          e.preventDefault();
          $('.prices__item__list').eq(i).toggleClass('prices__item__list_active');
          $('.prices__item__content').eq(i).toggleClass('prices__item__content_active');
        })
      });
    };
  
    toggleSlide('.prices__item__link');
    toggleSlide('.prices__item__back');
  
  });