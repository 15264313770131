import "../../../js/import/jquery.validate";
import "../../../js/import/jquery.maskedinput";
import "../../../js/import/messages_ru";

$(document).ready(function(){

  function valideForms(form) {
    $(form).validate({
      rules: {
        name: {
          required: true,
          minlength: 2
        },
        phone: "required",
        email: {
          required: true,
          email: true
        }
      },
      messages: {
        name: {
          minlength: jQuery.validator.format("Введите не менее {2} символов!")
        }
      }
    });
  };

  valideForms('#consultation-form');
  valideForms('#call-form');
  valideForms('#calculation-form');
  valideForms('#questions-form');

  $('input[name=phone]').mask("+375 (99) 999-99-99")

  // Modal
  $('[data-modal=call]').on('click', function() {
    $('.overlay, #call').fadeIn('slow');
  });

  $('[data-modal=calculation]').on('click', function() {
    $('.overlay, #calculation').fadeIn('slow');
  });
  
  $('.modal__close').on('click', function() {
    $('.overlay, #call, #thanks, #calculation').fadeOut('slow');
    $('form').trigger('reset');
  });

  $('#call-form').submit(function(e){
    e.preventDefault();
    if($(this).find("input").val() !='' && $(this).find("input").val() != null)
    {
      $.ajax({
        type: "POST",
        url: "mailer/smart.php",
        data: $(this).serialize()
        }).done(function() {
        $(this).find("input").val("");
        $('#call').fadeOut();
        $('.overlay, #thanks').fadeIn('slow');
    
        $('form').trigger('reset')
      });
      alert("Данные отправлены");
    }
    else {
      alert("Данные не отправлены");
    }
    return false;
  });

  $('#consultation-form').submit(function(e){
    e.preventDefault();
    if($(this).find("input").val() !='' && $(this).find("input").val() != null)
    {
      $.ajax({
        type: "POST",
        url: "mailer/smart.php",
        data: $(this).serialize()
        }).done(function() {
        $(this).find("input").val("");
        $('.overlay, #thanks').fadeIn('slow');
  
        $('form').trigger('reset')
      });
      alert("Данные отправлены");
    }
    else {
      alert("Данные не отправлены")
    }
    return false;
  });

  $('#calculation-form').submit(function(e){
    e.preventDefault();
    if($(this).find("input").val() !='' && $(this).find("input").val() != null)
    {
      $.ajax({
        type: "POST",
        url: "mailer/smartcalc.php",
        data: $(this).serialize()
        }).done(function() {
          $(this).find("input").val("");
        $('#calculation').fadeOut();
        $('.overlay, #thanks').fadeIn('slow');
  
        $('form').trigger('reset')
      });
      alert("Данные отправлены");
    }
    else {
      alert("Данные не отправлены")
    }
    return false;
  });

  $('#questions-form').submit(function(e){
    e.preventDefault();
    if($(this).find("input").val() !='' && $(this).find("input").val() != null)
    {
      $.ajax({
        type: "POST",
        url: "mailer/smart.php",
        data: $(this).serialize()
        }).done(function() {
        $(this).find("input").val("");
        $('.overlay, #thanks').fadeIn('slow');
  
        $('form').trigger('reset')
      });
      alert("Данные отправлены");
    }
    else {
      alert("Данные не отправлены")
    }
    return false;
  });

});