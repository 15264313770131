$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 1600) {
        $('.pageup').fadeIn();
    } else {
      $('.pageup').fadeOut();
    }
  });

  $(function(){
    $("a[href^='#up'], a[href^='#prices']").click(function(){
            var _href = $(this).attr("href");
            $("html, body").animate({scrollTop: $(_href).offset().top+"px"});
            return false;
    });
  });

});